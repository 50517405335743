import tw from "twin.macro";
import { NextPage } from "next";
import { NextSeo } from "next-seo";
import { BlockWrapper, Button, Link, Image } from "~/components/Base";
import { DefaultPage } from "~/layouts/DefaultPage";

const BigMarkBottom = tw(Image)`hidden lg:block absolute w-800 opacity-30 z-behind -left-80 bottom-0`;

const Headline = tw.h1`font-serif text-center font-bold text-30 mx-auto mb-20 lg:(text-40 mb-20)`;

const ErrorWrapper = tw.div`min-height[400px] height[50vh] mt-50 lg:mt-100 lg:height[75vh] flex flex-col items-center justify-center`;
const ButtonWrapper = tw.div``;

const Error: NextPage = () => {
  return (
    <>
      <NextSeo title="Pagina non trovata" />
      <DefaultPage navbarLayout="base" gradient="gradientBase">
        <BlockWrapper>
          <ErrorWrapper>
            <Headline>Non abbiamo trovato la pagina che cercavi.</Headline>
            <ButtonWrapper>
              <Button as={Link} href="/" $state="activeGreen" $size="big" tw="text-blue">
                Torna alla home page
              </Button>
            </ButtonWrapper>
          </ErrorWrapper>
        </BlockWrapper>
        <BigMarkBottom src="/images/big-restworld-mark-wizard-90deg.png" alt="Restworld mark" />
      </DefaultPage>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Error;
