import tw from "twin.macro";
import { NextSeo, NextSeoProps } from "next-seo";
// import { UserData } from "types";
import { UserData } from "@restworld/utility-types";
import { Gradient, Footer, CookieBanner } from "~/components/Base";
import { Navbar } from "~/components/Navbar";
import { GradientBackground } from "~/components/Base/Gradient";
import { Layouts } from "~/components/Base/Navbar";
import { Entry_Pages_Pages, GlobalSet_SeoGlobal } from "~/__statamic";
import GenericJsonLd from "~/components/JsonLd/GenericJsonLd";
import { AuthUser } from "~/contexts/JWTContext";

const Main = tw.main`relative w-full overflow-hidden text-white`;

interface Props {
  gradient?: GradientBackground;
  footerGradient?: GradientBackground;
  navbarLayout: Layouts;
  pageData?: Entry_Pages_Pages;
  globalSeo?: GlobalSet_SeoGlobal;
  jwt?: string;
  userData?: UserData | AuthUser | null;
}

export const DefaultPage: React.FC<Props> = ({
  children,
  gradient,
  navbarLayout,
  footerGradient,
  pageData,
  globalSeo,
}) => {
  // global seo
  const getGlobalStructuredData = function () {
    return globalSeo?.structured_data ?? [];
  };

  // page seo
  const seoTitle = pageData?.seo_title;
  const seoDescription = pageData?.seo_description;
  const seoImage = pageData?.og_image?.[0]?.url;
  const noIndex = pageData?.no_index;
  const canonical = pageData?.canonical;

  const pageSeoProps: NextSeoProps = {
    title: seoTitle,
    description: seoDescription,
    openGraph: {
      title: seoTitle,
      description: seoDescription,
      type: "website",
      locale: "it",
      images: seoImage ? [{ url: seoImage }] : undefined,
    },
    twitter: {
      cardType: "summary_large_image",
    },
    noindex: noIndex,
    canonical: canonical,
  };

  return (
    <>
      <NextSeo {...pageSeoProps} />
      {/* <GenericJsonLd fields={pageStructuredData} keyOverride={"page"} /> */}
      {getGlobalStructuredData().map((sd, i) => (
        <GenericJsonLd fields={JSON.parse(sd.json as string)} key={i} keyOverride={`global-${i}`} />
      ))}
      <CookieBanner />
      <Navbar layout={navbarLayout} />
      <Gradient background={gradient || "transparent"} />
      <Main>{children}</Main>
      <Footer background={footerGradient || "transparent"} />
    </>
  );
};
