import React, { FC } from "react";
import Head from "next/head";

const markup = (jsonld: string) => ({ __html: jsonld });

interface Props {
  keyOverride?: string;
  fields: object;
}

const GenericJsonLd: FC<Props> = ({ keyOverride, fields }) => {
  const jslonld = JSON.stringify(fields);

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(jslonld)}
        key={`jsonld-generic${keyOverride ? `-${keyOverride}` : ""}`}
      />
    </Head>
  );
};

// eslint-disable-next-line import/no-default-export
export default GenericJsonLd;
